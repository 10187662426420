<template>
  <div>
    <el-button type="primary" style="margin-bottom: 15px" @click="add"
      >新增版本</el-button
    >
    <el-table :data="list" style="width: 100%">
      <el-table-column
        prop="version"
        label="版本名称"
        width="180"
      ></el-table-column>
      <el-table-column prop="cost" label="金额" width="100"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column prop="signs" label="签约数量"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="edit(scope.row)"
            style="margin-right: 15px"
            >编辑</el-button
          >
          <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)">
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="!item.id ? '新增' : '编辑'"
      :visible.sync="show"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label="版本名称">
          <el-input v-model="item.version"></el-input>
        </el-form-item>
        <el-form-item label="版本金额">
          <el-input v-model="item.cost"></el-input>
        </el-form-item>
        <el-form-item label="功能权限">
          <el-checkbox-group v-model="item.permissions">
            <el-checkbox
              :label="item.id"
              v-for="item in permissionsList"
              :key="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.getList();
    this.getPermissions();
  },
  data() {
    return {
      permissionsList: [],
      list: [],
      total: 0,
      params: {
        pageNum: 1,
        pageSize: 100,
      },
      item: {},
      show: false,
    };
  },
  methods: {
    add() {
      this.item = {
        version: "",
        cost: 0,
        permissions: [],
        id: "",
      };
      this.show = true;
    },
    confirm() {
      this.$ajax
        .post(
          this.item.id ? "/basic/version/update" : "/basic/version/add",
          this.item
        )
        .then(() => {
          this.$message.success("保存成功");
          this.show = false;
          this.getList();
        });
    },
    del(id) {
      this.$ajax.post("/basic/version/del", { id }).then(() => {
        this.$message.success("删除成功");
        this.getList();
      });
    },
    edit(item) {
      this.item = {
        version: item.version,
        cost: item.cost,
        permissions: item.permissions,
        id: item.id,
      };
      this.show = true;
    },
    getList() {
      this.$ajax.post("/basic/version/getPage", this.params).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    getPermissions() {
      this.$ajax.post("/basic/version/permissions", {}).then((res) => {
        this.permissionsList = res.data;
      });
    },
  },
};
</script>

<style>
</style>